import { Component, OnInit } from "@angular/core";
import { ServicesService } from "../services/jrni/services.service";
import { DepartmentService } from "../services/jrni/department.service";
import { AvailabilityService } from "../services/jrni/availability.service";
import { DatastoreService } from "../services/datastore.service";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from "src/app/app.config";
import { ResourcesService } from "../services/jrni/resources.service";

@Injectable({
  providedIn: "root",
})

@Component({
  selector: "app-booking-summary",
  templateUrl: "./booking-summary.component.html",
  styleUrls: ["./booking-summary.component.scss"],
})
export class BookingSummaryComponent implements OnInit {
  selectedService;
  selectedLocation;
  selectedSlot;
  selectedDepartment;
  address;
  endSlot;
  src;
  selectedResource;
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;


  constructor(
    private servicesService: ServicesService,
    private departmentService: DepartmentService,
    private availabilityService: AvailabilityService,
    private datastoreService: DatastoreService,
    private http: HttpClient,
    private resourcesService: ResourcesService
  ) {}

  ngOnInit() {
    // Get the selected service
    this.servicesService.get().then((service) => {
      this.selectedService = service;
      
    });

    // Get the selected location
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.getLocation().then((location) => {
      this.selectedLocation = location;
    });

    // Get the selected address
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.getLocation().then((res) => {
      this.address = res;
    });

    if (this.datastoreService.selectedSlot) {
      this.availabilityService.getSelectedSlot().then((slot) => {
        this.selectedSlot = slot;
        this.availabilityService.getEndSlot().then((slot) => {
          this.endSlot = slot;
          this.datastoreService.selectedSlot = slot;
        });
        this.getResource(this.selectedSlot.resource_id).then((resource) => {
          this.resourcesService.set(resource);
          this.selectedResource =  resource.name;
        });
      })
    }
  }

  async getResource(resourceId) {
    return this.resourcesService.getResources(this.selectedLocation).then((resources) => {
      let foundResource
      for (const resource of resources) {
        if (resource.id === resourceId) {
          foundResource = resource;
          break; 
        }
      }
      return foundResource;
    });

  //   const headers: HttpHeaders = new HttpHeaders({
  //     'App-Id': this.core.app_id,
  //     'App-Key': this.core.app_key
  //   });
  //   // const apiUrl = `${this.apiServer.api_url}/api/v5/${location.id}/resources`;
      
  //   const resource = await this.availabilityService.getSelectedResource();
  //   const resourceId = resource.id; 
    
  
  //   console.log('Fetching resource data...');
  //   const apiUrl = `${this.apiServer.api_url}/api/v5/${location}/${resourceId}`;
  //   const selectedResource = await this.http.get(apiUrl, { headers }).toPromise();
  //   console.log('Resource data fetched:', selectedResource);

  //   console.log(selectedResource);
  //   return selectedResource;
  // } catch (error) {
  //   console.error('Error:', error);
  //   return null;

  }
}